import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import axios from '../../../utils/axios'

import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@material-ui/core'
import Error from '../../../layouts/Main/components/Error'
import { AUTH_API_URL, USER_ROLES } from '../../../config/constants'

import useStyles from './UserForm.styles'

const UserForm = ({ roles, ...props }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [user, setUser] = useState({
		id: null,
		userName: '',
		email: '',
		password: '',
		role: 'user'
	})

	const [error, setError] = useState(false)
	const [errorText, setErrorText] = useState(null)

	useEffect(() => {
		if (props.user) {
			const { userId, userName, email } = props.user
			setUser({ id: userId, userName: userName, email, password: '' })
		}
	}, [props])

	const handleChange = (prop) => (event) => {
		setUser({ ...user, [prop]: event.target.value })
	}

	const handleClose = (e, refreshList = false) => {
		props.onModalClose(e, refreshList)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		setError(false)
		setErrorText(null)

		const userApiUrl = `${AUTH_API_URL}/organizations/users`
		try {
			if (user.id) {
				await axios.patch(userApiUrl, user)
			} else {
				await axios.post(userApiUrl, user)
			}

			handleClose(e, true)
		} catch (e) {
			if (e.response) {
				const data = e.response.data
				setErrorText(data.error.message)
			}
			setError(true)
		}
	}

	return (
		<Dialog
			open={true}
			aria-labelledby="user-form-modal-title"
			aria-describedby="user-form-modal-description"
		>
			<DialogTitle id="simple-modal-title" disableTypography={true}>
				<Typography variant={'h3'}>
					{user.id ? t('user.edit', { name: user.userName }) : t('user.create')}
				</Typography>
			</DialogTitle>

			<Box paddingX={4} paddingY={2}>
				{error && <Error error={errorText} />}

				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<TextField
						required
						className={classes.textField}
						label={t('username')}
						fullWidth
						variant="outlined"
						defaultValue={user.userName}
						onChange={handleChange('userName')}
					/>
					<TextField
						type="email"
						required
						className={classes.textField}
						label={t('email')}
						fullWidth
						variant="outlined"
						defaultValue={user.email}
						onChange={handleChange('email')}
					/>
					<TextField
						type="password"
						required={!user.id}
						className={classes.textField}
						label={t('password')}
						fullWidth
						variant="outlined"
						onChange={handleChange('password')}
					/>

					<FormControl variant="outlined" fullWidth>
						<InputLabel id="role-select-label">{t('role')}</InputLabel>
						<Select
							required
							labelId="role-select-label"
							id="role-select"
							onChange={handleChange('role')}
							label={t('role')}
							value={user.role}
						>
							{roles.map((role) => (
								<MenuItem key={role} value={role}>
									{t(`role.${role}`)}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Box align="right" mt={4} mb={2}>
						<Button className={classes.button} color="secondary" onClick={handleClose}>
							{t('user.cancel')}
						</Button>
						<Button type="submit" variant="contained" color="primary">
							{t('user.save')}
						</Button>
					</Box>
				</form>
			</Box>
		</Dialog>
	)
}

export default UserForm
